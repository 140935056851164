
p, ul li, ol li {
  color: $lgrey;
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-family: $textfont;
}
.main-block {
    overflow: hidden;
    padding-top: 185px;
}


.side-nav {
  width: 200px;
  background-color: #fff;
  & .collapsible-header {
    overflow: hidden;
    height: 48px;
  }
  &  li > a > i.material-icons {
    float: right;
  }
}


//HEADER
.header-full {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}
.top-bar {
    padding: 10px 2%;
    background-color: #687981;
    width: 100%;
    overflow: hidden;
    & .btn {
      color: $white;
      background-color: $green;
    }
}
#phone {
  line-height: 36px;
  font-size: 1.6rem;
  padding-right: 40px;
  color: #5bc35f;
  font-weight: 600;
  & #phone-sub {
    font-size: 1.3rem;
    color: $white;
    font-weight: 300;
  }
}
.brand-logo {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
    align-items: center;
}


//HEADER

.split {
  height: 500px;
  width: 100%;
  clear: both; 
  overflow: hidden;
  position: relative;
}

#bgvid1 {
    width: 100%;
    display: block;
    float: left;
}
#bgvid2 {
    width: 100%;
    display: block;
    float: right;
}

.overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  &:hover {
    opacity: 1;
  }
  &.clear-blue {
    background-color: rgba(50, 90, 178, 0.4);
  }
  &.clear-red {
    background-color: rgba(244, 67, 54, 0.4);
  }
  & .btn.center {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 300px;
  }
}

.split-text {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  color: #fff;
}

.split1 {
  position: relative;
  height: 100%;
    width: 50%;
    float: left;
   & h2 {
      @extend .split-text;
      background-color: rgba(50, 90, 178, 0.6);
   }
}

.split2 {
  position: relative;
  height: 100%;
    width: 50%;
    float: left;
    & h2 {
      @extend .split-text;
      background-color: rgba(244, 67, 54, 0.6);
   }
}



#truck-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

        
.split2:hover { 
    

}
.split2:hover {
    & h2 {
      opacity: 1;
      transition: opacity 0.4s ease-in-out 1.1s;
    }
}


//NAVIGATION
$navheight: 130px;
.main-nav {
	justify-content: space-between;
    background-color: #FFFFFF;
    border-bottom: solid 1px #DBDBDB;
    border-top: solid 1px #DBDBDB;
    padding: 0 2%;
    height: $navheight;
    & #nav-mobile {
      & > li > a {
      	line-height: $navheight;
      	height: $navheight;
        font-size: 1.4rem;
      }
      & .divider {
        margin: 0;
        background-color: #bbb;
      }
    }
    & a {
		color: $lgrey;
	}
	& .button-collapse i, .brand-logo {
		line-height: $navheight;
		height: $navheight;
	}
	& .nav-wrapper {
		& #nav-mobile > li > a {
	    	line-height: $navheight;
	    	height: $navheight;
        & i {
          line-height: $navheight;
          height: $navheight;
          margin-left: 5px;
        }
	    }
	}
   
}

.dropdown-content li > a {
  color: $green;
  font-size: 1.2rem;
}

.service-btn {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  top: 32px;
  letter-spacing: 2px;
  border-radius: 15px;
  line-height: 48px;
  height: 48px;
  background-color: $white;
  color: #999;
  font-size: 1.4rem;
  box-shadow: none;
  border: solid 1px #DBDBDB;
  &:hover {
    background-color: $blue;
    color: $white;
  }
}


//SERVICES
.services {
    padding-bottom: 60px;
    &:after {
        content: '';
        z-index: -1;
        position: absolute;
        height: 50%;
        background-color: #F9F9F9;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

//ABOUT
.about {
    padding: 0;
    & h3 {
      color: $lgrey;
      font-weight: 600;
    }
    & .block-left {
        width: 60%;
        float: left;
        box-sizing: border-box;
        background-color: $lblue;
        position: relative;
        padding: 40px 120px 40px 100px;
        z-index: 1;
        height: 500px;
        
        & .divider {
            margin-bottom: 40px;
        }
    }
    & .block-right {
        width: 40%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        background-size: cover;
        background-position: center;
        background-color: $red;
        height: 500px;
        background-image: url('/img/aboutimg.jpg');
        background-size: cover;
    }
}

//INFO
.schedule-text {
  color: $lgrey;
  font-size: 1.8rem;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  text-transform: uppercase;
  display: block;
  padding: 20px 0 12px 0;
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
}
.info {
  padding-bottom: 60px;
}

//TESTIMONIALS
.testimonials {
  padding: 50px 0;
}
.testimonial {
  & p {
    text-align: center;
    color: $white;
    width: 600px;
    display: block;
    margin: 0 auto;
    font-size: 1.5rem;
  }
  & .client {
    color: $white;
    width: 100%;
    display: block;
    clear: both;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
  }
  & .divider.center {
      margin: 30px auto;
  }

}


.arrow {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  & i {
    color: rgba(0,0,0,0.1);
    font-size: 15rem;
    text-align: center;
    width: 100%;
    padding-top: 90px;
  }
}
.arrow-right {
  @extend .arrow;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(0,0,0,0.05);
  &:hover {
    background-color: #409443;
  }
}
.arrow-left {
  @extend .arrow;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(255,255,255,0.05);
  &:hover {
    background-color: #409443;
  }
}

//CONTACT 

.contact {
  padding: 80px 0 40px 0 !important;
  & .form-half {
    margin-top: 150px;
  }
}
#contact-bg {
  background-image: url('/img/hot.jpg');
  height: 550px;
  width: 80%;
  background-size: cover;
  background-position: bottom;
  display: block;
  overflow: hidden;
  padding: 170px 0;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  &.newbg {
    background-image: url('/img/breathe.jpg');
  }
  & p {
    font-size: 2.4rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 70px;
  }
  & .btn {
    margin: 0 auto;
    width: 350px;
    padding: 0;
    display: block;
    &:hover {

    }
  }
}

//FOOTER

  footer.page-footer {
  background-color: #eee;
  margin-top: 0;
    & > .container {
      padding: 40px 0;
      & a {
        color: $green;
      }
      & h3 {
        font-size: 2.02rem;
        color: #666;
      }
    }
    & .footer-copyright {
      color: #333;
      & span.rt-logo {
        color: #333;
        font-family: $logofont;
        font-size: 1.4rem;
      }
    }
    & .footer-icon {
      & img {
        max-width: 100%;
        width: 230px;
      }
    }
  }



/*************BUTTONS*************/
.light-green {
  background-color: $lgreen !important;
}
.green {
  background-color: $green !important;
}
//CIRCLE BUTTON
.ih-item {
  position: relative;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item,
.ih-item * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ih-item a {
  color: #333;
}
.ih-item a:hover {
  text-decoration: none;
}
.ih-item img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.ih-item .icon-block {
    width: 100%;
    height: 100%;
  & .icon {
        width: auto;
        height: 100px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 0 !important;
  }
}

.ih-item.circle {
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  margin: 0 auto 12px auto;
}
.ih-item.circle .img {
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 50%;
}

.ih-item.circle .img:before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 16px rgba(255, 255, 255, 0.6), 0 1px 2px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.ih-item.circle .img img {
  border-radius: 50%;
}
.ih-item.circle .circle-info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ih-item.circle.effect1 .spinner {
  width: 230px;
  height: 230px;
  border: 10px solid $green;
  border-right-color: $lgreen;
  border-bottom-color: $lgreen;
  border-radius: 50%;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.ih-item.circle.effect1 .img {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 10px;
  right: 0;
  width: auto;
  height: auto;
}
.ih-item.circle.effect1 .img:before {
  display: none;
}
.ih-item.circle.effect1.colored .circle-info {
  background: #1a4a72;
  background: rgba(26, 74, 114, 0.6);
}
.ih-item.circle.effect1 .circle-info {
  top: 10px;
  bottom: 0;
  left: 10px;
  right: 0;
  background: #333333;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}
.ih-item.circle.effect1 .circle-info h3 {
  color: #fff;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 2px;
  font-size: 22px;
  margin: 0;
  padding: 55px 0 0 0;
  height: 110px;
  text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
}
.ih-item.circle.effect1 .circle-info p {
  color: #bbb;
  padding: 10px 5px;
  font-style: italic;
  margin: 0 30px;
  font-size: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.ih-item.circle.effect1 a:hover .spinner {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ih-item.circle.effect1 a:hover .circle-info {
  opacity: 1;
}


//INFO LINK BUTTONS

/* Button 3b */
.info-btn {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 25px 80px;
  display: inline-block;
  margin: 15px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.info-btn:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-3b {
  padding: 25px 60px 25px 120px;
  border-radius: 10px;
  color: $white;
  font-size: 1.6rem;
  width: 440px;
  text-align: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  &.green {
    background-color: $lgreen;
  }
  &.red {
    background-color: $red;
  }
  &.blue {
    background-color: $blue;
  }
}

.btn-3b:before {
    border-right: 2px solid rgba(255,255,255,0.5);
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 85px;
    font-size: 140%;
    width: 80px;
    text-align: center;
    font-family: "FontAwesome";
}

.sun:before {
    content: "\f185";
}

.check:before {
    content: "\f046";
}

.request:before {
    content: "\f0f2";
}

.service:before {
    content: "\f046";
}


.finance-btn {
      margin-left: 30px;
    display: block;
    width: 440px;
    margin-top: 15px;
}

p.subtext {
  font-size: 13.4px;
  margin-bottom: 0;
  margin-top: 0;
}