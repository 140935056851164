@media screen and (max-width: 1750px) {
	.split {
    	height: 400px;
	}
}

@media screen and (max-width: 1500px) {
	html {
		font-size: 14px;
	}
	#truck-icon {
		max-width: 290px;
	}
}

@media screen and (max-width: 1400px) {
	html {
		font-size: 12px;
	}
	.container {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
	}
	.split {
    	height: 300px;
	}
	.split h2 {
		font-size: 1.8rem;
	}
	.info {
		& .row .col.s8 {
			width: 50%;
		}
		& .row .col.s4 {
			width: 50%;
		}
	}
	.about .block-right, .about .block-left {
		width: 50%;
	}
	.about .block-left {
	    padding: 20px 100px 20px 100px;
	}
}

@media screen and (max-width: 1280px) {
	.page .container {
		padding: 40px 10px 40px 10px !important;
	}

}

@media screen and (max-width: 1200px) {
	.about .block-left {
	    padding: 20px 80px 20px 80px;
	}
	.split {
	    height: 250px;
	}
}
@media screen and (max-width: 1024px) {
	.info .row .col.s8, .info .row .col.s4, .contact .row .col.s6 {
		width: 100%;
	}
	.contact .row .col.s6:first-child {
		display: none;
	}
	.contact .form-half {
	    margin-top: 0px;
	    padding: 0 200px;
	}
	.info .row .col.s4 a {
		margin: 10px auto;
		float: none;
		clear: both;
		display: block;
	}
	.service-btn {
		display: none;
	}
	
	.main-block {
    	padding-top: 135px;
	}
	.about .block-right {
		display: none;
	}
	.about .block-left {
		width: 100%;
		height: auto;
	}
	.schedule-text {
		width: 80%;
	}
	.info {
		padding: 20px 80px 40px 80px;
	}
	.split.header {
		& video {
			display: none !important;
		}
		background-image: url('/img/productionhead.jpg');
		background-position: center;
		background-size: cover;
	}
}

@media screen and (max-width: 950px) {
	.ih-item.circle {
	    width: 170px;
	    height: 170px;
	}

	.ih-item.circle.effect1 .spinner {
	    width: 180px;
	    height: 180px;
	}

	.ih-item.circle.effect1 .circle-info h3 {
		font-size: 1.2rem;
		padding: 45px 0 0 0;
		height: 90px;
	}
	.main-nav .button-collapse i, .main-nav .brand-logo, .main-nav .brand-logo img, .main-nav {
	    line-height: 80px;
	    height: 80px;
	}

	.page-footer .row {
		
		& .col.s2 {
			width: 50%;
			height: 180px;
			text-align: center;
		}
		& .col.s3, .col.s5 {
			display: none;
		}
	}
	footer.page-footer > .container {
		padding: 0px 80px 0px 80px;
		margin: 0;
	}

}

@media screen and (max-width: 800px) {
	.services {
		& .row .col.s3 {
			width: 100%;
		}
		&:after {
			display: none;
		}
	}

	.ih-item.circle {
	    width: 240px;
	    height: 240px;
	    margin: 20px auto;
	    display: block;
	}

	.ih-item.circle.effect1 .spinner {
	    width: 250px;
	    height: 250px;
	}

	.ih-item.circle.effect1 .circle-info h3 {
		font-size: 1.6rem;
		padding: 70px 0 0 0;
		height: 120px;
	}
	.ih-item.circle.effect1 .circle-info {
	    opacity: 1;
	}

	.split1 h2 {
		text-align: left;
		padding-left: 20px;
	}
	.split2 h2 {
		text-align: right;
		padding-right: 20px;
	}
	.contact .form-half {
	    padding: 0 100px;
	}
	.info, .about .block-left {
	    padding: 10px 100px 20px 100px;
	}
	.form-link h3 {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.split.header {
		
		background-image: url('/img/productionheadmob.jpg');
		background-position: center;
		background-size: cover;
	}
	.split1, .split2, #truck-icon {
		display: none;
	}
	.contact .form-half {
	    padding: 0 40px;
	}
	.info, .about .block-left {
	    padding: 10px 40px 20px 40px;
	}
	.steps {
		margin-bottom: 0;
	}
	.steps li {
		display: inline-block;
		overflow: hidden;
		margin-bottom: 5px;
	}
	.page-header {
    	height: 200px;
	}

	.page .row {
		& .col.s4, .col.s5, .col.s6, .col.s7 {
			width: 100%;
		}
	}
}

@media screen and (max-width: 600px) {
	.form-half {
		& .col.m6, .col.m12 {
			width: 100% !important;
		}
	}
	.contact .form-half {
	    padding: 0 10px;
	}
	.info, .about .block-left {
	    padding: 10px 10px 20px 10px;
	}
	footer.page-footer > .container {
    	padding: 0px 10px 0px 10px;
	}	
	.swiper-slide {
		max-width: 100%;
	}
	.btn.multi {
		display: none;
	}
	.top-bar #phone {
		float: none !important;
		width: 100%;
		display: block;
		text-align: center;
		padding: 0;
	}
	.info .row .col.s4 .info-btn {
		display: none;
	}
	.footer-copyright .container {
		padding: 0 10px;
		& .left {
			float: none !important;
			text-align: center;
			width: 100%;
			display: block;
		}
		& .right {
			float: none !important;
			text-align: center;
			width: 100%;
			display: block;
		}
	}
	footer.page-footer .footer-copyright {
		line-height: 30px;
		height: 60px;
	}
	#employment-application-page {
		& .col.s2, .col.s3, .col.s4, .col.s5, .col.s6, .col.s7, .col.s8 {
			width: 100%;
		}
		& .row {
			margin-bottom: 0;
		}
		& .radio-form {
			width: 100%;
			clear: both;
			margin-bottom: 10px;
			& label:first-child {
				display: block;
				clear: both;
				text-align: center;
			}
		}
	}

	.form-link h3 {
		font-size: 1.2rem;
	}
	.testimonial p {
		max-width: 98%;
		font-size: 1.2rem;
	}

	.remodal {
		padding: 15px;
		& h2 {
			font-size: 1.8rem;
		}
		& .col.m6 {
			width: 100%;
		}
		& .col.m12 {
			width: 100%;
		}
	}
}

@media screen and (max-width: 500px) {
	.finance-btn {
		max-width: 100%;
	}

}


@media screen and (max-width: 400px) {
	.page-header {
    	display: none;
	}
}