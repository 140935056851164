//placeholder text
::-webkit-input-placeholder {
    color:    $lgrey !important;
}
:-moz-placeholder {
    color:    $lgrey !important;
}
::-moz-placeholder {
    color:    $lgrey !important;
}
:-ms-input-placeholder {
    color:    $lgrey !important;
}

.radio-form {

	& label, [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
		display: inline-block;
		position: relative;
		font-size: 1.4rem;
		margin-right: 25px;
	}
	& [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
		margin-right: 15px;
	}

}

.inline {
	display: inline-block;
}

.form-text {
	display: inline-block;
	float: left;
}

.clear {
	clear: both;
}

.message {
	text-align: center;
	position: relative;
	color: #fff;
	padding: 10px;
	width: 100%;
	margin: 20px auto;
	clear: both;
	border-radius: 10px;
	& p {
		color: #fff;
		font-size: 1.3rem;
		margin: 10px 0;
	}
}
.close-btn {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}
#app-form {
	display: block;
	clear: both;
}
.success {
	@extend .message;
	background-color: $green;
}

.error {
	@extend .message;
	background-color: $red;
}

#employment-application-page {
	& .container {
		padding: 40px 0;
	}
}

.steps {
	display: block;
	margin: 0 auto 80px auto;
	text-align: center;
	clear: both;
	width: 100%;
	& li {
		display: inline;
		text-transform: uppercase;
		font-family: $logofont;
		padding: 8px 10px;
		background-color: $green;
		color: #fff;
		&.active {
			background-color: #185811;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3), 0 2px 10px 0 rgba(0, 0, 0, 0.3);
		}
	}
}


input:not([type]), input[type=text], input[type=password], input[type=email], input[type=url], input[type=time], input[type=date], input[type=datetime], input[type=datetime-local], input[type=tel], input[type=number], input[type=search], textarea.materialize-textarea {
    border-bottom: 1px solid $lgrey;
}