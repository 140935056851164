@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700|Raleway);
$mainfont: "Josefin Sans", sans-serif;
$textfont: "Raleway", sans-serif;
$logofont: "Lato", sans-serif;

$lgrey: #2b2b2b;
$lgreen: #D3EDCA;
/* $green: #4caf50; */

$green: #418a44;
$red: #F44336;
$blue: #325ab2;
$lblue: #DEE6F3;
$white: #fff;

html {
	font-family: $mainfont;
}

a, h1, h2, h3, h4, h5 {
	font-family: $mainfont;
}

p, span {
	font-family: $textfont;
}

.section {
	padding: 35px 0;
    display: block;
    position: relative;
    overflow: hidden;
	& h2, h3, h4 {
		color: $lgrey;
	}
	& h2 {
		font-size: 2.7rem;
		margin-bottom: 60px;
	}
	& h3 {
		font-size: 2rem;
		margin-bottom: 30px;
	}
}

.divider {
  height: 2px;
  margin: 40px 0;
  clear: both;
  width: 100px;
  background-color: #777;
  &.div-white {
    background-color: $white;
  }
  &.center {
    margin: 40px auto;
  }
  &.wide {
  	width: 80%;
  	margin: 70px auto;
  	&.light {
  		background-color: #bbb;
  		height: 1px;
  	}
  }
}

@mixin background-image($url) {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url($url);
}