.page {
	& h1 {
		font-size: 3rem;
		color: $lgrey;
		/* margin-bottom: 4rem; */
	}
	& h2 {
		font-size: 2.5rem;
	}
	& h2, h3, h4, h5 {
		color: #555;
	}
	& a {
		color: $green;
		font-family: $textfont;
	}
	& b {
		color: #666;
	}
	&#employment-page .row {
		padding: 120px 0;
	}
}

.page-header {
	height: 400px;
	width: 100%;
	display: block;
}

#services-page .page-header {
	@include background-image('/img/headers/services.jpg');
}
#about-page .page-header {
	@include background-image('/img/headers/about.jpg');
}
#information-page .page-header {
	@include background-image('/img/headers/info.jpg');
}

.form-link {
	padding: 30px 0;
	& h3 {
		margin: 0 auto;
    	width: 800px;
    	border: 1px solid #fff;
    	padding: 15px;
    	display: block;
	}
}

.inner-link {
	display: block;
    position: relative;
    top: -187px;
    visibility: hidden;
}