@import "materialize";
@import "swiper";
@import "vars";
@import "main";
@import "page";
@import "form";
@import "remodal";
@import "remodal-default-theme";
@import "queries";

.right {
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

#sidenav-overlay {
	display: none;
}

.gap-left{
	margin-left: 1em;
}

.centered-sidebar {
	display: block;
	margin: 10px auto;
	margin-left: 78px;
	padding-bottom: 10px;
	& img {
		display: block;
		margin: 0 auto;
	}
}